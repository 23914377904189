import React, { useState } from 'react';

import { Select, Spin } from 'antd';

import mockFilters from '@/mocks/filters.json'
import api from '@/services/api';

import paramsSerializer from '../../../../helpers/param-serializer';
import { FormItem } from './styles';


function filterDefaultValues(filterObj, type) {
  if (!filterObj || !filterObj[type.table] || !filterObj[type.table][type.field]) return [];

  const defaultValue = filterObj[type.table][type.field].map((i) => ({
    key: i.key,
    value: i.value,
    label: i.label
  }));

  return defaultValue;
}

function FilterItem({ paramsObj, filterObj, type, onChange }) {

  const { label, field, table, url } = type;

  const defaultValue = filterDefaultValues(filterObj, type);

  const [loadingItems, setLoadingItems] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText,] = useState('');

  // const delayedChangeText = useRef(
  //   debounce((text) => {
  //     setItems([]);
  //     setSearchText(text);
  //     requestFilters(1, text);
  //   }, 500)
  // ).current;

  const requestFilters = (reqPage, reqSearchText) => {
    const params = { filters: paramsObj }
    setLoadingItems(true);

    if (process.env.REACT_APP_MOCK === "true") {
      setItems(mockFilters[type.table][type.url])
    } else {
      api.get(`/filters/${url}`, { params, paramsSerializer }).then(({ data }) => {
        setItems(data);
        setLoadingItems(false);
      });
    }
  };

  const handleChangeFilter = (value) => onChange(value, field, table);

  const notFoundContent = loadingItems ? <Spin size="small" /> : 'Nenhum filtro encontrado';

  const paginationConfig = (e) => {
    e.persist();
    if (loadingItems) return;
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      setPage(page + 1);
      requestFilters(page + 1, searchText);
    }
  };

  const onVisibleChange = (open) => {
    if (!open) {
      setPage(1);
      setItems([]);
    } else {
      requestFilters(page, searchText);
    }
  };

  return (
    <FormItem label={label}>
      <Select
        showSearch
        labelInValue
        defaultValue={defaultValue}
        onChange={handleChangeFilter}
        mode="multiple"
        placeholder={label}
        filterOption={(inputValue, option) => option.children.toUpperCase().includes(inputValue.toUpperCase())}
        // filterOption={false}
        // onSearch={delayedChangeText}
        notFoundContent={notFoundContent}
        onPopupScroll={paginationConfig}
        onDropdownVisibleChange={onVisibleChange}
      >
        {items.map((v) => (
          // eslint-disable-next-line no-nested-ternary
          <Select.Option key={`${v.id ? v.id : v.name ? v.name : v}-opt`} value={v.id ? v.id : v.name ? v.name : v}>
            {v.name ? v.name : v}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
}

export default FilterItem;
