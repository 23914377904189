import { Input } from 'antd';
import styled from 'styled-components';
import { palette } from 'styled-theme';

export const Container = styled.div`
  width: 100%;
  padding: 10px 6px 6px 0;
  display: flex;
  flex-direction: column;
  background-color: ${palette('background_color', 0)};
`;

export const Top = styled.div`
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const SearchContainer = styled.div`
  width: 100% !important;
  display: flex;
  justify-content: space-between;

  .ant-btn {
    height: 40px;
    border-radius: 5px !important;
    margin: 0 0 0 15px;
  }
`;

export const ButtonHeaderContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-end;

  .buttons-group {
    display: flex;
    justify-content: flex-end;

    .dropdown {
      position: relative;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
    }

    .dropbtn {
      border-radius: 5px;

      @media (max-width: 1370px) {
        width: 8rem;
      }

      @media (max-width: 950px) {
        button {
          width: 100% !important;
        }
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #fff;
      min-width: 180px;
      max-height: 45vh;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      padding: 10px;
      overflow-y: scroll;

      .ant-checkbox {
        margin: 5px 0;
      }

      .ant-checkbox-group:first-child {
        margin: 5px;
      }

      .ant-form-item:last-child {
        margin: 25px 10px 15px 10px;
      }
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }

  .justification-button {
    background: #2c2c2c !important;
    border-color: #2c2c2c !important;

    .anticon,
    span {
      color: white;
    }

    &:hover {
      background: white !important;

      .anticon,
      span {
        color: inherit;
      }
    }
  }

  .cancel-button {
    background: #db525a !important;
    border: none;

    .anticon,
    span {
      color: white;
    }

    &:hover {
      background: #db525a !important;
      filter: brightness(1.1);

      .anticon,
      span {
        color: inherit;
      }
    }
  }

  .accept-refuse-button-container {
    display: flex;
    flex-direction: row;

    .accept-button {
      background-color: #58b957 !important;
      border: none;

      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        background-color: ${palette('grayscale', 3)} !important;
      }
    }

    .refuse-button {
      background-color: #db524b !important;
      border: none;

      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        background-color: ${palette('grayscale', 3)} !important;
      }
    }
  }
`;

export const SearchInput = styled(Input)`
  background: white;
  font-family: Roboto;
  width: 49% !important;
  height: 40px;
  border-radius: 50px !important;
  border: 1px solid ${palette('side_border', 0)} !important;
  background: ${palette('light_background_color', 0)} !important;

  .ant-input {
    background-color: ${palette('light_background_color', 0)} !important;
  }

  .ant-input-prefix {
    font-size: 20px;
    padding-right: 10px;
  }

  .ant-input-clear-icon:last-child {
    font-size: 18px;
    vertical-align: middle;
    padding-top: 6px;
  }
`;

export const ContainerTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
`;

export const RangePickerContainerStyle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  min-width: 200px;

  .picker-calendar {
    font-size: 12px;
    margin-left: 8px;
  }

  .date-container {
    height: 40px;
    background-color: transparent;
    display: flex;
    width: 100%;
    border-radius: 5px;
    justify-content: start;
    align-self: center;
    align-items: center;
  }

  .date-picker {
    height: 100% !important;
    border-radius: 5px !important;

    input {
      font-size: 12px;
      max-width: 80px;
    }
  }

  .date-text {
    font-weight: bold;
  }

  .anticon {
    margin-left: -7px;
  }
`;

export const ConfirmModalHeader = styled.div`
  margin-bottom: 10px;

  .header-icon {
    color: #faad14;
    font-size: 18px;
    margin-right: 15px;
  }

  .header-title {
    font-size: 18px !important;
    font-weight: bold;
  }
`;
