import { Row, Col } from 'antd';
import styled from 'styled-components';
import { palette } from 'styled-theme';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--header-height) - 15px);
`;

export const Content = styled(Col)`
  height: 100%;
`;

export const EmptyData = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette('text', 1)};
`;