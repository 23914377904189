import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import moment from 'moment';

import { getRole } from '@/auth/redux/reducer';
import Loader from '@/components/Loader';
import ManagementTable from '@/containers/Offers/components/OfferTable';

import { getMain } from '../../redux/reducer';
import StatusTag from '../../utils/StatusTag';
import Header from '../Header';
import { Container, ListContainer } from './styles';

export default function OffersList(props) {
  const isLoadingOffers = useSelector(getMain.loadingOffers);
  const offers = useSelector(getMain.getOffers);
  const searchText = useSelector(getMain.getSearchOffers);
  const selectedOffersIds = useSelector(getMain.getOfferIds);
  const selectedStatuses = useSelector(getMain.getSelectedStatuses);
  const role = useSelector(getRole);

  const getFirstOffer = useMemo(() => {
    return offers.filter((offer) => {
      return selectedOffersIds.includes(offer.id);
    })[0];
  }, [offers, selectedOffersIds]);

  const offersMemo = useMemo(() => {
    let filteredOffers = offers;

    const getStatus = (offer) => {
      return offer.status;
    };

    if (!isEmpty(searchText)) {
      filteredOffers = filteredOffers.filter((offer) => {
        return (
          offer.user_name.toUpperCase().includes(searchText.toUpperCase()) ||
          offer.local_name.toUpperCase().includes(searchText.toUpperCase()) ||
          offer.gifts?.join(', ').includes(searchText.toUpperCase())
        );
      });
    }

    if (!isEmpty(selectedStatuses)) {
      filteredOffers = filteredOffers.filter((offer) => {
        return selectedStatuses.includes(getStatus(offer));
      });
    }

    if (!isEmpty(selectedOffersIds)) {
      const selectedOffer = getFirstOffer;

      if (!isEmpty(selectedOffer)) {
        filteredOffers = filteredOffers.filter((offer) => {
          return selectedOffer.status === offer.status;
        });
      }
    }

    return filteredOffers.map((offer) => {
      return {
        ...offer,
        key: offer.id,
        statusTag: StatusTag[getStatus(offer)],
        scheduled_time_begin: moment(offer.scheduled_time_begin).format('DD/MM/YYYY HH:mm'),
        scheduled_time_end: moment(offer.scheduled_time_end).format('DD/MM/YYYY HH:mm'),
      };
    });
  }, [searchText, offers, selectedStatuses, selectedOffersIds, getFirstOffer]);

  const ListOffers = () => (
    <ListContainer>
      <ManagementTable data={offersMemo} loading={isLoadingOffers} />
    </ListContainer>
  );

  const ComponentListOffers = () => {
    const showOfferActions = getFirstOffer?.status === 'pending';

    return (
      <Container>
        <Header showOfferActions={showOfferActions} />
        {ListOffers()}
      </Container>
    );
  };

  return isLoadingOffers ? <Loader /> : ComponentListOffers();
}
