import _ from 'lodash';
import moment from 'moment';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    syncOffers: ['dates', 'filters'],
    syncOffersSuccess: ['offers'],
    syncOffersError: null,

    saveOffers: ['data', 'callback'],
    saveOffersSuccess: null,
    saveOffersError: null,

    curationOffers: ['data', 'callback'],
    curationOffersSuccess: null,
    curationOffersError: null,

    setOfferIds: ['offerIds'],
    setDates: ['dates'],

    setSearchOffers: ['searchOffers'],
    setFilterValues: ['filterValues'],

    resetStateOffers: null,

    setSelectedStatuses: ['selectedStatuses'],
  },
  { prefix: 'OffersReducer/' }
);

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loadingOffers: false,
  offers: [],
  visit: {},
  dates: [moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  filters: [],

  offerIds: [],
  errorSaveOffers: false,
  loadingSaveOffers: false,

  offerOptions: [],
  errorOffer: null,
  loadingOffer: false,

  formulariesOptions: [],
  loadingFormulariesOptions: false,

  loadingCurationOffers: false,

  searchOffers: '',

  filterOptions: [],
  filterValues: {},
  filterKeys: {
    status: { name: 'Status', group: 'general' },
    userParentName: { name: 'Nome do Supervisor', group: 'general' },
    userName: { name: 'Cargo', group: 'general' },
    LocalName: { name: 'Nome da Loja', group: 'general' },
  },

  selectedStatuses: [],

  loadingCancellation: false,
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_OFFERS]: (state, { filters }) => {
    return { ...state, offerIds: [], filters, loadingOffers: true, selectedStatuses: [] };
  },
  [Types.SYNC_OFFERS_SUCCESS]: (state, { offers }) => {
    return {
      ...state,
      offers,
      loadingOffers: false,
    };
  },
  [Types.SYNC_OFFERS_ERROR]: (state) => {
    return { ...state, loadingOffers: false };
  },

  [Types.SET_SEARCH_OFFERS]: (state, { searchOffers }) => {
    return { ...state, searchOffers };
  },
  [Types.SET_FILTER_VALUES]: (state, { filterValues }) => {
    return { ...state, filterValues: _.pickBy(filterValues) };
  },
  [Types.SET_OFFER_IDS]: (state, { offerIds }) => {
    return { ...state, offerIds };
  },
  [Types.SET_DATES]: (state, { dates }) => {
    return { ...state, dates };
  },

  [Types.SAVE_OFFERS]: (state) => {
    return { ...state, errorSaveOffers: false, loadingSaveOffers: true };
  },
  [Types.SAVE_OFFERS_SUCCESS]: (state) => {
    return { ...state, loadingSaveOffers: false, errorSaveOffers: false };
  },
  [Types.SAVE_OFFERS_ERROR]: (state) => {
    return { ...state, errorSaveOffers: true, loadingSaveOffers: false };
  },

  [Types.CURATION_OFFERS]: (state) => {
    return { ...state, loadingCurationOffers: true };
  },
  [Types.CURATION_OFFERS_SUCCESS]: (state) => {
    return { ...state, loadingCurationOffers: false };
  },
  [Types.CURATION_OFFERS_ERROR]: (state) => {
    return { ...state, loadingCurationOffers: false };
  },

  [Types.SET_SELECTED_STATUSES]: (state, { selectedStatuses }) => {
    return { ...state, selectedStatuses}
  },

  [Types.RESET_STATE_OFFERS]: (state) => ({ ...INITIAL_STATE }),
});

export const PATH = 'OffersReducer';

const root = (selector) => {
  return (state) => state[PATH][selector];
};

/* Selectors */

export const getMain = {
  loadingOffers: (state) => state[PATH].loadingOffers,
  emptyOffers: (state) => state[PATH].offers <= 0,
  getOffers: (state) => state[PATH].offers,
  getVisit: (state, index) => state[PATH].offers[index],
  totalOffers: (state) => state[PATH].offers.length,
  getLoadingForm: (state) => state[PATH].loadingSendForm,
  getDates: (state) => state[PATH].dates,
  getFilters: (state) => state[PATH].filterValues,

  getSearchOffers: (state) => state[PATH].searchOffers,
  getOfferIds: (state) => state[PATH].offerIds,
  getErrorSaveOffers: (state) => state[PATH].errorSaveOffers,
  getLoadingSaveOffers: (state) => state[PATH].loadingSaveOffers,

  getOfferOptions: (state) => state[PATH].offerOptions,
  loadingOffer: (state) => state[PATH].loadingOffer,
  errorOffer: (state) => state[PATH].errorOffer,

  getSelectedStatuses: (state) => state[PATH].selectedStatuses,
  getLoadingCurationOffers: (state) => state[PATH].loadingCurationOffers,

  getLoadingCancellationOffers: (state) => state[PATH].loadingCancellation,
};

export const filterKeys = (state) => state[PATH].filterKeys;
export const filterValues = (state) => state[PATH].filterValues;
