import { combineReducers } from 'redux';

import { reducer as auth } from '@/auth/redux/reducer';
import { reducer as FormReducer, path as formPath } from '@/containers/Form/redux/reducer';
import GalleryReducer from '@/containers/Gallery/redux/reducer';
import { reducer as JustificationsReducer } from '@/containers/Justifications/redux/reducer';
import { reducer as OfferDetailReducer } from '@/containers/OfferDetail/redux/reducer';
import { reducer as OffersReducer } from '@/containers/Offers/redux/reducer';
import ReportsReducer, { path as reportsPath } from '@/containers/Reports/redux/reducer';
import { reducer as ScheduleEditReducer } from '@/containers/ScheduleEdit/redux/reducer';
import { reducer as SchedulesReducer } from '@/containers/Schedules/redux/reducer';
import { reducer as UsersReducer, PATH as UsersPath } from '@/containers/Users/redux/reducer';
import VisitsManagementWeeklyReducer from '@/containers/VisitManagement/redux/reducer';
import { reducer as StatusDayReducer } from '@/containers/VisitManagementDaily/components/StatusDay/redux/reducer';
import VisitsManagementDailyReducer from '@/containers/VisitManagementDaily/redux/reducer';
import VisitsReducer from '@/containers/Visits/redux/reducer';
import { reducer as UploadingReducer, PATH as uploadingReducerPath } from '@/redux/UploadPooling/reducer';

export default combineReducers({
  auth,
  VisitsReducer,
  GalleryReducer,
  VisitsManagementWeeklyReducer,
  VisitsManagementDailyReducer,
  [formPath]: FormReducer,
  StatusDayReducer,
  SchedulesReducer,
  [UsersPath]: UsersReducer,
  ScheduleEditReducer,
  [reportsPath]: ReportsReducer,
  JustificationsReducer,
  [uploadingReducerPath]: UploadingReducer,
  OffersReducer,
  OfferDetailReducer,
});
