import { createActions, createReducer } from 'reduxsauce';
import SeamlessImmutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    setItemId: ['itemId'],

    syncItem: null,
    syncItemSuccess: ['info'],
    syncItemError: null,

    resetState: null,
  },
  { prefix: 'OfferDetailReducer/' }
);

export { Types };
export default Creators;

export const INITIAL_STATE = SeamlessImmutable({
  itemId: null,
  itemInfo: {},
  loadingItem: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ITEM_ID]: (state, { itemId }) => {
    return { ...state, itemId };
  },

  [Types.SYNC_ITEM]: (state) => {
    return {
      ...state,
      loadingItem: true,
    };
  },
  [Types.SYNC_ITEM_SUCCESS]: (state, { info }) => {
    return { ...state, loadingItem: false, itemInfo: info };
  },
  [Types.SYNC_ITEM_ERROR]: (state) => {
    return { ...state, loadingItem: false, info: {} };
  },

  [Types.RESET_STATE]: (state) => ({ ...INITIAL_STATE }),
});

export const path = 'OfferDetailReducer';

export const loadingItem = (state) => state[path].loadingItem;
export const info = (state) => state[path].itemInfo;
