const serialize = (obj, prefix) => {
  const str = [];

  Object.keys(obj).forEach( (key) => {
    const checkArray = Array.isArray(obj) ? `${prefix  }[]` : `${prefix  }[${key}]`;

    const k = prefix ? checkArray : key; const v = obj[key];
      
    str.push((v !== null && typeof v === 'object') ? serialize(v, k) : `${encodeURIComponent(k)  }=${  encodeURIComponent(v)}`);
  })
  
  return str.join('&');
};

export default serialize;
