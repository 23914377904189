import React from 'react';

import { Tag } from 'antd';

const CustomTag = ({ color, text }) => {
  return (
    <div>
      <Tag color={color}>
        <span>
          {text}
        </span>
      </Tag>
    </div>
  );
}

export default CustomTag;
