/* eslint-disable import/no-cycle */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import mock from '@/mocks/offer.json';
import api from '@/services/api';

import Actions, { Types, path } from './reducer';


export function* syncItemSagas(action) {
  try {
    const itemId = yield select((state) => state[path].itemId);

    const {data} = yield call(api.get, `/evaluation_form/${itemId}`)

    yield put(Actions.syncItemSuccess(data));

  } catch (error) {
    requestError(error);
    yield put(Actions.syncItemError());
  }
}

export function* updateItem(action) {
  yield put(Actions.syncItem());
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_ITEM, syncItemSagas),
    takeLatest(Types.SET_ITEM_ID, updateItem),
  ]);
}
