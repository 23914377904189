import React, { useState } from 'react';

import { Form, Modal, Input } from 'antd';

import { WarningOutlined } from '@ant-design/icons';

import { ConfirmModalHeader } from '../Header/styles';

const { TextArea } = Input;

export default function ConfirmModal({
  handleConfirm,
  visible,
  approved,
  onCancel,
  loadingCurration,
}) {
  const form = Form.useForm()[0];
  const [comment, setComment] = useState('');

  const handleConfirmButton = () => {
    const values = form ? form.getFieldsValue() : {};

    handleConfirm({ comment: values?.comment, approved });
  };

  const RenderTitle = () => (
    <ConfirmModalHeader>
      <h3 className="header-title">
        {`Deseja realmente ${approved ? 'aceitar' : 'recusar'} a(s) ação(ões)?`}
      </h3>
      <span>Essa ação não poderá ser desfeita</span>
    </ConfirmModalHeader>
  );

  const RenderModalTitle = () => {
    return <span>Avaliação de ação</span>;
  };

  return (
    <Modal
      title={RenderModalTitle()}
      visible={visible}
      onOk={handleConfirmButton}
      okText="Confirmar"
      cancelText="Cancelar"
      onCancel={onCancel}
      closable={false}
      centered
      okButtonProps={{ loading: loadingCurration, disabled: !approved && comment.length < 1 }}
    >
      <RenderTitle />
      {!approved && (
        <Form layout="vertical" name="control-hooks" form={form}>
          <Form.Item required name="comment" label="Informe o motivo da recusa:">
            <TextArea
              onChange={(e) => setComment(e.target.value)}
              style={{ resize: 'none' }}
              rows={4}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
