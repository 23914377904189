/* eslint-disable import/no-cycle */
import React from 'react';

import { notification } from 'antd';
import _ from 'lodash';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import mockVisits from '@/mocks/visits.json';
import api from '@/services/api';

import Actions, { Types, getMain } from './reducer';

export function* syncOffers(action) {
  // Mock Visits
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.syncOffersSuccess(mockVisits.data));
  }

  const dates = yield select(getMain.getDates);
  const filters = yield select(getMain.getFilters);

  const page = 1;

  try {
    const { data } = yield call(
      api.get,
      `/evaluation_form?date_start="${dates[0]}"&date_end="${dates[1]}"`,
      { params: { page } }
    );

    yield put(Actions.syncOffersSuccess(data?.offers));
  } catch (error) {
    requestError(error);
    yield put(Actions.syncOffersError());
  }
}

function* saveOffers(action) {
  const { data, callback } = action;

  try {
    yield call(api.post, `/offer_formularies`, data);

    yield put(Actions.saveOffersSuccess());
    notification.success({
      message: 'Sucesso!',
      description: 'As visitas foram justificativas com sucesso!.',
    });

    setTimeout(() => {
      callback();
    }, 500);
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao buscar as justificativas. Tente novamente mais tarde...',
    });

    yield put(Actions.saveOffersError());
  }
}

function* curationOffers(action) {
  const { data, callback } = action;
  const selectedOffersIds = yield select(getMain.getOfferIds);

  const params = {
    status: data.approved ? 'accept' : 'refused',
  }

  if (data?.comment) {
    params.refusal_message = data.comment;
  }
  
  try {
    const requests = selectedOffersIds.map((id) =>
      call(api.patch, `/evaluation_form/${id}`, params)
    );

    yield all(requests);

    yield put(Actions.curationOffersSuccess());
    notification.success({
      message: 'Sucesso!',
      description: `Sucesso ao ${data.approved ? 'aceitar' : 'recusar'} a(s) justificativa(s)!`,
    });

    setTimeout(() => {
      callback(null, true);
    }, 500);
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro. Tente novamente mais tarde...',
    });

    yield put(Actions.curationOffersError());
  }
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_OFFERS, syncOffers),
    takeLatest(Types.SAVE_OFFERS, saveOffers),
    takeLatest(Types.SET_FILTER_VALUES, syncOffers),
    takeLatest(Types.CURATION_OFFERS, curationOffers),
  ]);
}
