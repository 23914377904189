import React, { Component } from 'react';

import { Form, Button } from 'antd';
import cloneDeep from 'lodash.clonedeep';

import FilterItem from './components/FilterItem';
import { StyledDrawer, Footer, FilterItemsContainer } from './styles';

class FilterDrawer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filterObj: {},
      paramsObj: {},
    };

    this.filterObjBkp = {};
    this.paramsObjBkp = {};
  }

  setFilter = (value, field, table) => {
    const { filterObj, paramsObj } = this.state;

    if (filterObj[table]) {
      filterObj[table][field] = value;
      paramsObj[table][field] = value.map((id) => (field === 'document' ? id.label : id.value));
    } else {
      filterObj[table] = { [field]: value };
      paramsObj[table] = {
        [field]: value.map((id) => (field === 'document' ? id.label : id.value)),
      };
    }

    this.setState({ filterObj, paramsObj });
  };

  cancelFilters = () => {
    const { onApplyFilters } = this.props;
    this.setState(
      {
        filterObj: { ...this.filterObjBkp },
        paramsObj: { ...this.paramsObjBkp },
      },
      () => {
        onApplyFilters(this.state.paramsObj);
      }
    );
  };

  clearFilters = () => {
    const { onApplyFilters } = this.props;
    this.setState({ filterObj: {}, paramsObj: {} });
    this.filterObjBkp = {};
    this.paramsObjBkp = {};
    onApplyFilters(null);
  };

  applyFilters = () => {

    const { filterObj, paramsObj } = this.state;
    const { onApplyFilters } = this.props;
    this.filterObjBkp = cloneDeep(filterObj);
    this.paramsObjBkp = cloneDeep(paramsObj);
    onApplyFilters(paramsObj);
  };

  render() {
    const { visible, filtersConfig } = this.props;
    const { filterObj, paramsObj } = this.state;

    return (
      <StyledDrawer
        width="45vw"
        title="Filtros"
        placement="right"
        destroyOnClose
        visible={visible}
        afterVisibleChange={this.onVisibleChange}
        onClose={this.cancelFilters}
      >
        <Form layout="vertical">
          <FilterItemsContainer>
            {filtersConfig.map((type) => (
              <FilterItem
                type={type}
                filterObj={filterObj}
                paramsObj={paramsObj}
                key={type.label}
                onChange={this.setFilter}
              />
            ))}
          </FilterItemsContainer>
          <Footer>
            <Button onClick={this.clearFilters} style={{ marginRight: 8 }}>
              Limpar
            </Button>
            <Button onClick={this.applyFilters} type="primary">
              Aplicar filtros
            </Button>
          </Footer>
        </Form>
      </StyledDrawer>
    );
  }
}

export default FilterDrawer;
