/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import auth from '@/auth/redux';
import form from '@/containers/Form/redux';
import gallery from '@/containers/Gallery/redux/sagas';
import justifications from '@/containers/Justifications/redux';
import offerDetail from '@/containers/OfferDetail/redux/index';
import offers from '@/containers/Offers/redux/index';
import reports from '@/containers/Reports/redux/sagas';
import scheduleEdit from '@/containers/ScheduleEdit/redux';
import schedules from '@/containers/Schedules/redux';
import users from '@/containers/Users/redux/index';
import visitsManagementWeekly from '@/containers/VisitManagement/redux/sagas';
import statusDay from '@/containers/VisitManagementDaily/components/StatusDay/redux';
import visitsManagementDaily from '@/containers/VisitManagementDaily/redux/sagas';
import visits from '@/containers/Visits/redux/sagas';
import uploadPooling from '@/redux/UploadPooling/index';

export default function* rootSaga() {
  yield all([
    auth(),
    ...visits,
    ...gallery,
    ...visitsManagementWeekly,
    ...visitsManagementDaily,
    form(),
    statusDay(),
    schedules(),
    scheduleEdit(),
    users(),
    justifications(),
    ...reports,
    uploadPooling(),
    offers(),
    offerDetail(),
  ]);
}
