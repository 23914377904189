import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@/components/Loader';

import VisitsList from './components/OffersList';
import OffersActions, { getMain } from './redux/reducer';
import { Container, Content, EmptyData } from './styles';

export default function Offers(props) {
  const dispatch = useDispatch();
  const dates = useSelector(getMain.getDates);

  useEffect(() => {
    dispatch(OffersActions.syncOffers());
  }, [dispatch, dates]);

  const isLoadinVisits = useSelector(getMain.loadingOffers);

  return <Container>{isLoadinVisits ? <Loader /> : <VisitsList />}</Container>;
}
