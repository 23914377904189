import { Button, Drawer } from 'antd';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 12px;
  }

  .ant-drawer-body {
    padding: 12px;
  }
`;

export const Footer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;

export const FilterItemsContainer = styled.div`
  margin-bottom: 50px;

  .ant-form-item-label {
    padding: 0 !important;
  }
`;
