import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, DatePicker } from 'antd';
import _, { has } from 'lodash';
import moment from 'moment';

import { getPermissions } from '@/auth/redux/reducer';
import FilterDrawer from '@/components/FilterDrawer';
import {
  SearchOutlined,
  FilterOutlined,
  FileSearchOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import OffersActions, { getMain } from '../../redux/reducer';
import ConfirmModal from '../ConfirmModal';
import {
  Container,
  Top,
  SearchContainer,
  SearchInput,
  ContainerTitle,
  ButtonHeaderContainer,
  RangePickerContainerStyle,
} from './styles';

const { RangePicker } = DatePicker;

export default function Header({ showOfferActions }) {
  const dispatch = useDispatch();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [typeConfirmModal, setTypeConfirmModal] = useState(null);

  const defaultDates = useSelector(getMain.getDates);
  const defaultFilters = useSelector(getMain.getFilters);
  const searchText = useSelector(getMain.getSearchOffers);
  const offerIds = useSelector(getMain.getOfferIds);
  const loadingCurationOffers = useSelector(getMain.getLoadingCurationOffers);
  const permissions = useSelector(getPermissions);

  const [dateStart, setDateStart] = useState(moment(defaultDates[0]));
  const [dateEnd, setDateEnd] = useState(moment(defaultDates[1]));

  const [lastDateStart, setLastDateStart] = useState(moment(defaultDates[0]));
  const [lastDateEnd, setLastDateEnd] = useState(moment(defaultDates[1]));

  const handleConfirmModalVisible = (type = null, refresh = false) => {
    setTypeConfirmModal(type);
    setShowConfirmModal((prevState) => !prevState);
    if (refresh) {
      dispatch(OffersActions.syncOffers());
    }
  };

  const finishChangeDates = (end) => {
    setDateEnd(moment(end));
    const newDate = [dateStart, moment(end)];
    if (newDate !== defaultDates) {
      dispatch(
        OffersActions.setDates([
          moment(dateStart).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
        ])
      );
    }
    setLastDateEnd(end);
    setLastDateStart(dateStart);
  };

  const onChangeDate = ([start, end]) => {
    setDateStart(moment(start));
    if (
      (end?.format('YYYY-MM-DD') !== lastDateEnd?.format('YYYY-MM-DD') && end !== null) ||
      (dateStart?.format('YYYY-MM-DD') !== lastDateStart?.format('YYYY-MM-DD') && end !== null)
    )
      finishChangeDates(end);
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(defaultFilters);

  const handleSearchContent = (text) => {
    dispatch(OffersActions.setSearchOffers(text));
  };

  const debounceOnChange = _.debounce(handleSearchContent, 300);

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const applyFilters = (filters) => {
    dispatch(OffersActions.setFilterValues(filters));
    toggleFilters();
  };

  const plainOptions = [
    { label: 'Pendente', value: 'pending' },
    { label: 'Aprovada', value: 'accept' },
    { label: 'Recusada', value: 'refused' },
  ];

  const onChangeCheckbox = (opt) => {
    if (opt.length === 0) {
      dispatch(OffersActions.setSelectedStatuses(null));
    } else {
      dispatch(OffersActions.setSelectedStatuses(opt));
    }
  };

  const handleConfirmButton = (data) => {
    dispatch(OffersActions.curationOffers(data, handleConfirmModalVisible));
  };

  return (
    <Container>
      {showConfirmModal && (
        <ConfirmModal
          visible={showConfirmModal}
          approved={typeConfirmModal}
          handleConfirm={handleConfirmButton}
          onCancel={() => setShowConfirmModal(false)}
          loadingCurration={loadingCurationOffers}
        />
      )}
      <Top>
        <ContainerTitle>Ações</ContainerTitle>
        <span>Ações criadas pela equipe</span>
      </Top>

      <FilterDrawer
        onClose={toggleFilters}
        visible={filtersVisible}
        currentFilters={currentFilters}
        onApplyFilters={applyFilters}
        filtersConfig={[
          { table: 'users', field: 'user_name', label: 'Nome do Promotor', url: 'promoters' },
          {
            table: 'locals',
            field: 'user_parent_name',
            label: 'Nome do Supervisor',
            url: 'supervisors',
          },
          { table: 'locals', field: 'local_name', label: 'Nome da Loja', url: 'locals' },
        ]}
      />

      <SearchContainer>
        <SearchInput
          prefix={<SearchOutlined />}
          defaultValue={searchText}
          onChange={(e) => debounceOnChange(e.target.value)}
          placeholder="Buscar por loja, ações ou pelo supervisor..."
        />
        <ButtonHeaderContainer>
          <RangePickerContainerStyle>
            <div className="date-container">
              <RangePicker
                className="date-picker"
                allowClear={false}
                format="DD-MM-YYYY"
                placeholder={['Início', 'Fim']}
                // onChange={onChangeDate}
                onCalendarChange={onChangeDate}
                defaultValue={[dateStart, dateEnd]}
                allowEmpty={false}
              />
            </div>
          </RangePickerContainerStyle>
          <div className="buttons-group">
            {has(permissions, 'can_evaluate_offers') && (
              <div className="accept-refuse-button-container">
                <Button
                  type="primary"
                  className="accept-button"
                  icon={<CheckOutlined />}
                  onClick={() => handleConfirmModalVisible(true)}
                  disabled={!offerIds.length}
                >
                  Aceitar
                </Button>
                <Button
                  type="primary"
                  className="refuse-button"
                  icon={<CloseOutlined />}
                  onClick={() => handleConfirmModalVisible(false)}
                  disabled={!offerIds.length}
                >
                  Recusar
                </Button>
              </div>
            )}
            <div className="dropdown filter-button" style={{ gridArea: 'status' }}>
              <Button type="primary" className="dropbtn ">
                <FilterOutlined className="filter-icon" />
                Status
              </Button>
              <div className="dropdown-content">
                <Checkbox.Group onChange={onChangeCheckbox} options={plainOptions} />
              </div>
            </div>
            {/* <Button icon={<FilterOutlined />} onClick={toggleFilters}>Filtros</Button> */}
          </div>
        </ButtonHeaderContainer>
      </SearchContainer>
    </Container>
  );
}
