import styled from 'styled-components';
import { palette } from 'styled-theme';

export const Container = styled.div`
  height: 100%;
  padding: 24px 14px 0 14px;
  margin: 10px 15px;
  display: flex;
  flex-direction: column;
  background-color: ${palette('background_color', 0)};
`;

export const ListContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;
