import _ from 'lodash';

const fixedInitialAnswers = (data, visitId, formId) => {
  const arr = {};
  data.forEach((item) => {
    let name;

    if (item.brand_id) {
      name = `#${item.brand_id}-${visitId}-${formId}`;
    } else {
      name = `#${item.product_id}-${visitId}-${formId}`;
    }

    arr[name] = {
      ...item,
      value: item.value,
      visit_id: visitId,
    };
  });

  return arr;
};

const dynamicInitialAnswers = (data, visitId, formType, productName) => {
  const arr = {};
  const productFormTypes = ['product_form', 'assortment_product_form'];
  const isProductForm = productFormTypes.includes(formType);

  data.forEach((item) => {
    let name = '';

    const namePrefix = item.brand_id || item.question_id || item.question_type;
    if (!_.isEmpty(item.product_category))
      name = `#${namePrefix}-${visitId}-${item.form_id}-${item.product_category_id}`;
    else if (isProductForm) name = `#${item.question_id}-${productName}`;
    else name = `#${item.question_id || item.id}`;

    arr[name] = {
      ...item,
      value: item.value,
    };
  });

  return arr;
};

const normalizeAnswers = (answers) => {
  const cleanAnswers = _.map(answers, (o) => _.omit(o, ['options']));

  return cleanAnswers;
};

export { fixedInitialAnswers, dynamicInitialAnswers, normalizeAnswers };
